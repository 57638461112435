import {Layout, Menu, Space, Typography, Modal, Button, Dropdown,
     message, Form, Input, Table, Pagination, Tooltip, Row, Col} from 'antd';
import { CaretDownOutlined, SearchOutlined, ExportOutlined } from '@ant-design/icons';
import React, {useState, useEffect, useContext, useMemo} from 'react';
import './rrk.css';
import Breadcrumb from "antd/es/breadcrumb";

import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import {MapComponent} from '@terrestris/react-geo';
import XYZ from "ol/source/XYZ";
import OlSourceTileWMS from "ol/source/TileWMS";
import axios from 'axios';
import WorkspaceContext from '../WorkspaceContext';
import download from './download';


const {Header, Content, Sider} = Layout;
const { Search } = Input;
const {Text, Title} = Typography;
const fetch = require('sync-fetch');

const RegionalResourceKitBrowse = (props) => {

    const [hierarchy, setHierarchy] = useState([]);
    const [category, setCategory] = useState([]);
    const [region, setRegion] = useState(props.regionReq);
    const {selectedDatasets, setSelectedDatasets, setRegionReq, setDefaultKeys, 
        setSelectedKeys, defaultKeys, selectedKeys} = useContext(WorkspaceContext);
    const [categoryDetails, setCategoryDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    // const [defaultKeys, setDefaultKeys] = useState([])
    // const [selectedKeys, setSelectedKeys] = useState([])
    const [openSearch, setOpenSearch] = useState(false)
    const [searchResult, setSearchResult] = useState([])

    const [searchCols, setSearchCols] = useState([
        {
            title: 'Region',
            dataIndex: ['dataset_collections', '0', 'name'],
            key: 1 ,
            render: (text, record) => 
            {
                let region = text.replace('Regional Resource Kits', "")
                return (
                    <div>
                        {region}
                    </div>
                ) 
            }
        },
        {
          title: 'Title',
          dataIndex: 'name',
          key: 2       
        },
        {
          title: 'Description',
          dataIndex: 'report_description_template',
          key: 3,
          render: (text, record) => 
          {
            let description = text ? text : record.description
            let ellipsis = description.length > 251 ? description.slice(0, 250) + '...' : description
            return (
                <div>
                        <span size="small">{ellipsis}</span>
                    {/* <Tooltip title={description} placement='right'> */}
                    {/* </Tooltip> */}
                </div>
            )  
          }      
        },
        {
            title: 'Action',
            dataIndex: 'actions',
            key: 4,
            render: (text, record) => {
                return <div>
                    <Tooltip title="Navigate to Data Source" placement='top'>
                        <Button onClick={()=> navToDataset(record)} type="primary" size="small" icon={<ExportOutlined />}></Button>
                    </Tooltip>
                </div>
            }
        }
      ])

    useEffect(() => {
        let hierarchy = fetch(`rrk_metadata/${region}/index.json`).json();
        setHierarchy(hierarchy);
        setRegionReq(region);
        setCategory([]);
        setCategoryDetails(null);

    }, [region]);

    useEffect(() => {
        setRegion(props.regionReq);
    }, [props.regionReq]);

    useEffect(() => {
        let getCategoryDetails = (list) => {
            let term_list = list.map(e => encodeURIComponent(e))
            let url = `rrk_metadata/${region}/` + term_list.join('/') + '.json';
            let details = fetch(url).json();
            return details;

            // try {
            //     let details = fetch(url).json();
            //     return details;
            // } catch (e) {
            //     console.log(e);
            //     return null;
            // }
        }

        try {
            console.log("props.category")
            console.log(props.category)
            let details = getCategoryDetails(props.category);
            setCategory(props.category);
            setCategoryDetails(details);
        } catch (e) {
            setCategory([]);
            setCategoryDetails(null);
        }

    }, [props.category]);

    const onClick = (e) => {
        console.log('onclick e')
        console.log(e)
        let split_path = e.key.split('/').splice(1)
        let selected_keys = getSelectedKeys(split_path)

        console.log(selected_keys)

        // setDefaultKeys(selected_keys);
        setSelectedKeys(selected_keys)

        setCategory(e.key.split("/"));
        setCategoryDetails(getCategoryDetails(e.key.split("/")));
    };

    const backgroundLayer = new OlLayerTile({
        source: new XYZ({
            crossOrigin: "anonymous",
            url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
        })
    });

    const getLayerConfig = () => {
        let term_list = category.map(e => encodeURIComponent(e))
        let url = `rrk_metadata/${region}/` + term_list.join('/') + '.json';
        try {
            let layerConfig = fetch(url).json();
            return layerConfig;
        } catch (e) {
            return null;
        }
    }

    const getCategoryDetails = (list) => {
        let term_list = list.map(e => encodeURIComponent(e))
        let url = `rrk_metadata/${region}/` + term_list.join('/') + '.json';
        try {
            let details = fetch(url).json();
            return details;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    var layers = [backgroundLayer];

    // important: this prevents re-rendering the map
    const map = useMemo(() => {
        if (category == null) return null;
        const backgroundLayer = new OlLayerTile({
            source: new XYZ({
                crossOrigin: "anonymous",
                url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
            })
        });
        return new OlMap({
            layers: [backgroundLayer],
            view: new OlView({
                projection: 'EPSG:4326',
                center: region === 'Southern California' ? [-118.4194, 33.9592] : [-119.9916, 38.529901],
                zoom: region === 'Southern California' ? 7 : 6
            }),
        })
    }, [region, category]);

    var layerConfig = getLayerConfig();
    var legend_url = null;
    var dataLayer = null;
    if (layerConfig && layerConfig.mapping) {
        for (var i = 0; i < layerConfig.mapping.length; i++) {           
            dataLayer = new OlLayerTile({
                visible: true,
                opacity: 0.7,
                type: 'WMS',
                source: new OlSourceTileWMS({
                    url: layerConfig.mapping[i].url,
                    crossOrigin: "anonymous",
                    params: {
                        'LAYERS': layerConfig.mapping[i].layer,
                        'TILED': true
                    },
                    serverType: 'geoserver',
                })
            });
            layers.push(dataLayer);
            map.setLayers(layers);
            break;
        }
    }

    if (dataLayer && dataLayer.getSource) {
        legend_url = dataLayer.getSource().getLegendUrl(map.getView().getResolution());
        legend_url += 'width=5&height=10&TRANSPARENT=true&legend_options=fontName:Times%20New%20Roman;fontAntiAliasing:true;fontSize:10';
        setTimeout(() => dataLayer.getSource().refresh(), 1000);
    }

    const inWorkspace = () => {
        console.log("!layerConfig")
        if (!layerConfig) return false;
        for (let i = 0; i < selectedDatasets.length; i++) {
            if (selectedDatasets[i].category && selectedDatasets[i].title && selectedDatasets[i].mapping) {
                console.log(selectedDatasets[i].mapping[0].layer === layerConfig.mapping[0]['layer'])
                if ((selectedDatasets[i].category + "/" + selectedDatasets[i].title) === category.join("/") &&
                    selectedDatasets[i].mapping[0].layer === layerConfig.mapping[0]['layer']) {
                    return true;
                }
            }

        }
        return false;
    }

    const addToWorkspace = () => {
        if (!inWorkspace()) {
            let newSelectedDatasets = [...selectedDatasets];
            newSelectedDatasets.push(layerConfig);
            setSelectedDatasets(newSelectedDatasets);
        }
    }

    const removeFromWorkspace = () => {
        for (let i = 0; i < selectedDatasets.length; i++) {
            if ((selectedDatasets[i].category + "/" + selectedDatasets[i].title) === category.join("/")) {
                let newSelectedDatasets = [...selectedDatasets];
                newSelectedDatasets.splice(i, 1);
                setSelectedDatasets(newSelectedDatasets);
                break;
            }
        }
    }

    const showModal = () => {
        setOpenSearch(true)
      };

    const closeModal = () => {
        setOpenSearch(false)
    };

    const clearSearch = () => {
        setSearchResult([])
    }

    const retrieveSearch = async (search_terms) => {
        console.log("retrieveSearch")
        console.log(search_terms)

        const breakpoint = /\W/
        const terms = search_terms.split(breakpoint).filter(str => str !== '').join(' & ');

        const results = await fetch('https://sparcal.sdsc.edu/api/v1/Dataset/?search_terms=' + encodeURIComponent(terms) + '&skip=0&limit=100&order_by=dataset_id&ascending=true',
            {
                method: "GET",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
            })
        

        let search_result = results.json()

        console.log("search_result")
        console.log(search_result)

        let its_single_row = search_result.filter(e => e.data_type === 'vector')[0]

        if (its_single_row){
            its_single_row.name = its_single_row.dataset_collections ? its_single_row.dataset_collections[0].name : ''
            search_result = search_result.filter(e => e.data_type !== 'vector')
            search_result.push(its_single_row)
        }

        let unique_results = []

        search_result.forEach(res => {
            let is_unique = unique_results.filter(e => e.file_path === res.file_path && e.dataset_collections[0].name === res.dataset_collections[0].name).length == 0
            if(is_unique){
                unique_results.push(res)
            }
        })

        console.log(unique_results)

        setSearchResult(unique_results)
    }

    function searchJSON(obj, key, path) {
        for (let k in obj) {
            let vals = obj[k]
            if(vals.label === key){
                path.push(vals.key)
            } else if(vals.children !== undefined && vals.children.length !== 0) {
                searchJSON(vals.children, key, path)
                if(path.length !== 0){
                    break;
                }
            }
        }

        return path
    }

    const getSelectedKeys = (split_path) => {

        let selectedKeys = []
        let prevItem = ''
        split_path.forEach(e => {
            let key = prevItem + '/' + e
            selectedKeys.push(key)
            prevItem = key
        })

        return selectedKeys
    }

    const navToDataset = async (record) => {
        
        closeModal()

        if(record.dataset_collections[0].name.includes('Regional Resource Kit')){
            let region = record.dataset_collections[0].name.replace(' Regional Resource Kits', "")
            let url = `rrk_metadata/${region}/index.json`

            let title = record.name

            let hierarchy = fetch(url).json();
            let path = searchJSON(hierarchy, title, [])[0]

            let split_path = path.split('/').splice(1)
            let selected_keys = getSelectedKeys(split_path)

            console.log(selected_keys)
        
            await setRegion(region)
            await setDefaultKeys(selected_keys)
            await setSelectedKeys(selected_keys)
            await setCategory(split_path);
            await setCategoryDetails(getCategoryDetails(split_path));

            onClick({'key' : path, 'keyPath' : selected_keys})
        } else {
            props.setDisplay("map");

            let itsSelected = selectedDatasets.filter(e => e.folder === '/Interagency Tracking System')

            let checkedDatasets = {...props.checkedDatasets}

            itsSelected.forEach(e => {
                checkedDatasets['https://sparcal.sdsc.edu/geoserver/ITS/wms/' + e.mapping[0].layer ] = true
            })

            props.setCheckedDatasets(checkedDatasets)
        }
    }

    const onOpenChange = (keys) => {
        let rootSubmenuKeys = hierarchy.map(e => {
            return e.key
        })

        console.log("keys")
        console.log(keys)
        console.log("rootSubmenuKeys")
        console.log(rootSubmenuKeys)

        const latestOpenKey = keys.find((key) => defaultKeys.indexOf(key) === -1);
        console.log("latestOpenKey")
        console.log(latestOpenKey)
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setDefaultKeys(keys);
            setSelectedKeys(keys)
        } else {
            console.log('in here')
            setDefaultKeys(latestOpenKey ? [latestOpenKey] : []);
            setSelectedKeys(keys)
        }
    };

    // prevent loading spinner
    setTimeout(() => {
        const element = document.getElementById("map");
        element.className = '';
    }, 100);

    const regions = [
        {
            key: 'Central California',
            label: 'Central California',
        },
        {
            key: 'Northern California',
            label: 'Northern California',
        },
        {
            key: 'Sierra Nevada',
            label: 'Sierra Nevada'
        },
        {
            key: 'Southern California',
            label: 'Southern California',
        },
    ];   

    const selectRegion = ({ key }) => {
        setRegion(key);
    };

    const colorBgContainer = "white";
 

    return (       

        <div id="data_browse"
             style={{
                 display: props.display === 'data' ? 'flex' : 'none',
                 flexDirection: 'column',
                 width: '100%',
                 height: 'calc(100vh - 40px)'
             }}>
            <Layout>
                <Header
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#EEEDE3',
                        height: 40,
                        marginLeft: -27,
                        color: 'black',
                        fontWeight: 'bold',
                    }}
                >
                    Regional Resource Kit
                    <span style={{width: 20, color:'lightgray'}}></span>
                    {/* <Button type="primary"
                            style={{
                                backgroundColor: '#D11E00',
                                borderColor: "white"
                            }}
                            onClick={() => onClick({'key' : '/Fire Dynamics/Functional Fire/Current Fire Return Interval Departure, Since 1970'})}>
                        ahhhhhh
                    </Button> */}

                    <Dropdown menu={{
                                items: regions,
                                onClick: selectRegion,
                              }}
                              placement={"bottomLeft"}
                              style={{ color: 'black' }}>
                        <span>
                            {region}
                            <CaretDownOutlined style={{color: 'gray', paddingLeft: 5}}/>
                        </span>
                    </Dropdown>

                    <Button style={{ marginLeft: 'auto', color:'black' }} icon={<SearchOutlined />} ghost 
                        onClick={showModal}>
                        Search
                    </Button>

                    <Modal 
                        title="Search Data Sources"
                        open={openSearch}
                        onCancel={closeModal}
                        footer={null}
                        style={{
                            minWidth:'1000px',
                            zIndex: -1
                        }}
                        >                            
                        <div>
                            <Form>
                                <Form.Item 
                                    label="Search RRK"
                                    name="search">
                                    <Search
                                        allowClear
                                        onSearch={retrieveSearch} />
                                </Form.Item>
                            </Form>
                            <Table columns={searchCols} 
                                dataSource={searchResult}
                                pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '50']}}
                                />
                            {/* <Button onClick={clearSearch} size='small' style={{whiteSpace: "normal", height: 'auto'}}>Clear Table</Button> */}
                        </div>
                    </Modal>

                    {/*<Text strong style={{marginLeft: 'auto', marginRight: '-10pt'}}>*/}
                    {/*    <span style={{fontWeight: 'normal'}}>Region :</span> {region}*/}
                    {/*</Text>*/}
                </Header>
                <Layout style={{display: 'flex', height: '100%'}}>
                    <Sider width={300}
                           style={{
                               background: colorBgContainer,
                               paddingTop: 10,
                               overflow: 'auto'
                           }}>
                        <Menu
                            mode="inline"
                            id='rrkMenu'
                            style={{
                                height: '100%',
                                borderRight: 0,
                                textTransform: 'capitalize',                                
                                whiteSpace: 'normal',
                            }}                            
                            onOpenChange={onOpenChange}
                            openKeys={defaultKeys}
                            selectedKeys={selectedKeys}
                            items={hierarchy}
                            onClick={onClick}
                            forceSubMenuRender={true}
                        />
                    </Sider>
                    <Layout style={{padding: '5px 5px 5px'}}
                    >
                        <Content
                            style={{
                                padding: 24,
                                margin: 0,
                                background: colorBgContainer,
                                overflow: 'auto'
                            }}
                        >
                            {
                                categoryDetails ?
                                    <div>
                                        <Breadcrumb>
                                            <Breadcrumb.Item>{""}</Breadcrumb.Item>
                                                {
                                                category.map((item, i) =>
                                                    i < category.length - 1 ?
                                                        <Breadcrumb.Item key={"category-" + i}>{item === '' ? region : item}</Breadcrumb.Item>
                                                        : null
                                                )
                                            }
                                        </Breadcrumb>

                                        <div style={{paddingLeft: '5pt'}}>
                                            <Title level={3} style={{textTransform: 'capitalize', padding: '5pt 0'}}>
                                                {category[category.length - 1]}
                                            </Title>
                                            {
                                                categoryDetails && categoryDetails.mapping ?
                                                    <div>
                                                        <table style={{width: '100%', height: '100%'}}>
                                                            <tbody>
                                                            <tr>
                                                                <td style={{
                                                                    height: 400,
                                                                    width: 500,
                                                                    minWidth: 500,
                                                                    minHeight: 400,
                                                                    position: 'relative'
                                                                }}>
                                                                    <MapComponent map={map}
                                                                                  style={{
                                                                                      position: 'absolute',
                                                                                      top: 0,
                                                                                      left: 0,
                                                                                      zIndex: 800
                                                                                  }}/>
                                                                    <img id="legend"
                                                                         src={legend_url}
                                                                         alt=""
                                                                         style={{
                                                                             left: 10,
                                                                             bottom: 10,
                                                                             position: 'absolute',
                                                                             zIndex: 900
                                                                         }}></img>
                                                                </td>
                                                                <td style={{
                                                                    minWidth: 500,
                                                                    padding: '3px 15px',
                                                                    verticalAlign: 'top'
                                                                }}>
                                                                    <table border={0}>
                                                                        <tbody>
                                                                        <tr>
                                                                            <td style={{
                                                                                whiteSpace: 'nowrap',
                                                                                verticalAlign: 'top',
                                                                                width: '30%',
                                                                                minWidth: 130
                                                                            }}>
                                                                                <Text strong style={{paddingRight: 8}}>
                                                                                    Tier:
                                                                                </Text>
                                                                            </td>
                                                                            <td><Text>{categoryDetails['tier']}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{
                                                                                whiteSpace: 'nowrap',
                                                                                verticalAlign: 'top',
                                                                                width: '30%'
                                                                            }}>
                                                                                <Text strong style={{paddingRight: 8}}>Data
                                                                                    Vintage:
                                                                                </Text>
                                                                            </td>
                                                                            <td>
                                                                                <Text>{categoryDetails['data_vintage']}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{
                                                                                whiteSpace: 'nowrap',
                                                                                verticalAlign: 'top',
                                                                                width: '30%'
                                                                            }}>
                                                                                <Text strong style={{paddingRight: 8}}>
                                                                                    Resolution:
                                                                                </Text>
                                                                            </td>
                                                                            <td>
                                                                                <Text>{categoryDetails['data_resolution']}</Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} style={{
                                                                                whiteSpace: 'nowrap',
                                                                                verticalAlign: 'top'
                                                                            }}>
                                                                                <Text strong style={{paddingRight: 8}}>
                                                                                    Data File(s):
                                                                                </Text>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2} style={{paddingLeft: 15}}>
                                                                                <div>
                                                                                    {categoryDetails['file_name'].split(';')[0]}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={2}
                                                                                style={{padding: '5px 15px 5px 15px'}}>
                                                                                <Space>
                                                                                    {
                                                                                        inWorkspace() ?
                                                                                            <Button type="primary"
                                                                                                    style={{
                                                                                                        backgroundColor: '#D11E00',
                                                                                                        borderColor: "white"
                                                                                                    }}
                                                                                                    onClick={removeFromWorkspace}>
                                                                                                Remove from Workspace
                                                                                            </Button>
                                                                                            :
                                                                                            <Button type="primary"
                                                                                                    onClick={addToWorkspace}>
                                                                                                Add to Workspace
                                                                                            </Button>
                                                                                    }
                                                                                    {
                                                                                        <Button type="primary"
                                                                                                onClick={() => {
                                                                                                    setLoading(true);
                                                                                                    download(layerConfig.mapping[i].layer,
                                                                                                        categoryDetails['file_name'].split(';')[0],
                                                                                                        setLoading
                                                                                                    );
                                                                                                }}
                                                                                                loading={loading}
                                                                                        >
                                                                                            Download
                                                                                        </Button>
                                                                                    }

                                                                                </Space>
                                                                            </td>
                                                                        </tr>
                                                                        {
                                                                            loading ?
                                                                            <tr>
                                                                                <td colSpan={2}
                                                                                    style={{padding: '15px 15px 5px 15px'}}>
                                                                                    <div style={{
                                                                                        width: 260,
                                                                                        borderRadius: '25px',
                                                                                        border: '1px solid lightgray',
                                                                                        padding: '15px'
                                                                                    }}>
                                                                                        The data packing process typically
                                                                                        completes within a few seconds, but
                                                                                        depending on the size of the data
                                                                                        being downloaded, it may take up
                                                                                        to 2 minutes in the slowest case.
                                                                                    </div>
                                                                                </td>
                                                                            </tr> : null
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>

                                                        <div style={{marginTop: 15}}
                                                             dangerouslySetInnerHTML={{
                                                                 __html:
                                                                     '<span style="padding-right: 8px; font-weight: bold;">Data Units: </span>'
                                                                     + categoryDetails['data_units']
                                                             }}
                                                        />

                                                        {
                                                            categoryDetails['metric_definition_and_relevance'] ?
                                                                <div style={{marginTop: 10}}>
                                                                    <div className="data-info"
                                                                         dangerouslySetInnerHTML={{
                                                                             __html:
                                                                                 '<span style="padding-right: 8px; font-weight: bold;">Metric Definition and Relevance: </span>'
                                                                                 + categoryDetails['metric_definition_and_relevance']
                                                                         }}
                                                                    />
                                                                </div> : null
                                                        }

                                                        {
                                                            categoryDetails['creation_method'] ?
                                                            <div style={{marginTop: 10}}>
                                                                <div className="data-info"
                                                                     dangerouslySetInnerHTML={{
                                                                         __html:
                                                                             '<span style="padding-right: 8px; font-weight: bold;">Creation Method: </span>'
                                                                             + categoryDetails['creation_method']
                                                                     }}
                                                                />
                                                            </div> : null
                                                        }

                                                        {
                                                            categoryDetails['represent_element_and_pillar'] ?
                                                                <div style={{marginTop: 10}}>
                                                                    <div className="data-info"
                                                                         dangerouslySetInnerHTML={{
                                                                             __html:
                                                                                 '<span style="padding-right: 8px; font-weight: bold;">Represent Element and Pillar: </span>'
                                                                                 + categoryDetails['represent_element_and_pillar']
                                                                         }}
                                                                    />
                                                                </div> : null
                                                        }

                                                        {
                                                            categoryDetails['type_and_distribution_of_data'] ?
                                                                <div style={{marginTop: 10}}>
                                                                    <div className="data-info"
                                                                         dangerouslySetInnerHTML={{
                                                                             __html:
                                                                                 '<span style="padding-right: 8px; font-weight: bold;">Type and Distribution of Data: </span>'
                                                                                 + categoryDetails['type_and_distribution_of_data']
                                                                         }}
                                                                    />
                                                                </div> : null
                                                        }

                                                        {
                                                            categoryDetails['translation'] ?
                                                                <div style={{marginTop: 10}}>
                                                                    <div className="data-info"
                                                                         dangerouslySetInnerHTML={{
                                                                             __html:
                                                                                 '<span style="padding-right: 8px; font-weight: bold;">Translation: </span>'
                                                                                 + categoryDetails['translation']
                                                                         }}
                                                                    />
                                                                </div> : null
                                                        }

                                                        {
                                                            categoryDetails['translation_method_and_outcome'] ?
                                                                <div style={{marginTop: 10}}>
                                                                    <div className="data-info"
                                                                         dangerouslySetInnerHTML={{
                                                                             __html:
                                                                                 '<span style="padding-right: 8px; font-weight: bold;">Translation Method and Outcome: </span>'
                                                                                 + categoryDetails['translation_method_and_outcome']
                                                                         }}
                                                                    />
                                                                </div> : null
                                                        }

                                                        <div style={{marginTop: 10}}>
                                                            <div className="data-info"
                                                                 dangerouslySetInnerHTML={{
                                                                     __html:
                                                                         '<span style="padding-right: 8px; font-weight: bold;">Credits: </span>'
                                                                         + categoryDetails['data_source']
                                                                 }}
                                                            />
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <Title level={3}>
                                            {region} Regional Resource Kit
                                        </Title>

                                        <div style={{marginTop: 10}}>
                                            <div className="data-info">

                                                <Title level={4}>Data Access</Title>

                                                <p>
                                                    The resource kit contains a core set of data layers that reflect
                                                    management-relevant metrics for the {region} region.
                                                    These data and metrics have been vetted by federal, state, and
                                                    academic scientists. In total, the {region} Regional
                                                    Resource Kit contains nearly 70 metrics selected to be informative,
                                                    meaningful, and actionable for management.
                                                </p>

                                                <p>
                                                    Most data layers are available at 30m resolution, but some are
                                                    available at the resolution of the original data set (e.g. the
                                                    California gnatcatcher suitable habitat data layer was developed
                                                    at 150 m pixel resolution).
                                                </p>


                                                <Title level={4}>Use Constraints</Title>


                                                <p>
                                                    These data were collected from multiple sources, some which
                                                    have specified use constraints. All data developed by the U.S.
                                                    Government can be used without additional permissions or fees.
                                                    Data developed by other sources may have other constraints.
                                                    Please check each metric and their source to determine if any
                                                    additional disclaimers exist. A full list of disclaimers by
                                                    organization is available at the end of this webpage.
                                                </p>

                                                <p>
                                                    Appropriate use includes regional assessments of vegetation
                                                    cover, land cover, or land use change trends, total extent
                                                    of vegetation cover, land cover, or land use change, and
                                                    aggregated summaries of vegetation cover, land cover, or land
                                                    use change. Further use includes applying these data to assess
                                                    management opportunities for treatments to restore landscape
                                                    resilience using GIS software and specialized Decision Support
                                                    Tools.
                                                </p>

                                                <Title level={4}>Data Products</Title>

                                                <p>
                                                    The metrics are organized by the 10 pillars of resilience in
                                                    the Framework for Resilience. Each pillar represents a resource
                                                    outcome associated with resilient forest landscapes. There are
                                                    ~70 metrics within the resource kit that span the 10 pillars.
                                                    The Metrics describe the characteristics of the elements (key
                                                    characteristics) of each pillar in quantitative or, in a few
                                                    cases, qualitative terms. Metrics are used to assess, plan for,
                                                    measure, and monitor progress toward desired outcomes and
                                                    greater resilience. Metrics are selected to be informative,
                                                    meaningful, and actionable to meet the needs of management.
                                                </p>

                                                <p>
                                                    The metrics are also divided into three "tiers." Among all
                                                    these metrics, some are created and relevant statewide. Other
                                                    metrics are more suited to conditions within a given region.
                                                    The "Tiers" for metrics included in each RRK:
                                                </p>

                                                <p>
                                                    Tier 1 – metrics that are a single, consistent data layer,
                                                    developed statewide; they can also be clipped to the boundary
                                                    of the region so values within that region are the only ones
                                                    included for calculations or regional statistics. Example:
                                                    Annual Burn Probability.
                                                </p>

                                                <p>
                                                    Tier 2 – metrics relevant to a single region or relevant to
                                                    multiple Regions but data layers differ among Regions because
                                                    of varied data availability (sources) across Regions. Example:
                                                    California gnatcatcher habitat suitability.
                                                </p>

                                                <p>
                                                    Tier 3 - metrics are those that would be of interest to some
                                                    land managers for specific applications but not included as a
                                                    core metric in an RRK. Example: Distribution of the Quino
                                                    checkerspot butterfly.
                                                </p>

                                                <p>
                                                    Each RRK will contain all Tier 1 and Tier 2 data together to
                                                    comprise the kit. Tier 3 data will be pointed to for reference
                                                    and use, as needed.
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                            }
                        </Content>
                    </Layout>
                </Layout>
            </Layout>

        </div>
    );
};

export default RegionalResourceKitBrowse;
