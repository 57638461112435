import * as React from 'react';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceTileWMS from 'ol/source/TileWMS';
import ScaleLine from 'ol/control/ScaleLine';
import config from './config';
import ForestDataMapPopover from './ForestDataMapPopover';
import ForestDataMapLayerTree from './ForestDataMapLayerTree';
import ForestDataMapQueryTool from "./ForestDataMapQueryTool";
import ForestDataMapIntersect from "./ForestDataMapIntersect";
import ForestDataMapReset from "./ForestDataMapReset";
import ForestDataMapDownload from './ForestDataMapDownload'
import {getMinMax, getSLDForLayer} from "./utils";
import ForestDataMapSaveWorkspace from './ForestDataMapSaveWorkspace';
import DragDropSupport from "./DragDropSupport";
import { UserContext } from '../UserContext';
import DatasetCards from '../components/DatasetCard'
import {useEffect} from "react";
import {Modal} from 'antd';


export default class ForestDataMap extends React.Component {

    constructor(props) {
        super(props);
        this.mapDivId = `map-${Math.random()}`;

        this.state = {
            queryLayerInfo: [],
            checkedQuerysets: {},
            queryLayers: [],
            layersByUser: [],
            reminder_visible: false,
            categories: [
                // "Watershed & Habitat Improvement",
                "Tree Planting",
                "Timber Harvest",
                "Sanitation & Salvage",
                "Mechanical and Hand Fuels Reduction",
                "Grazing",
                "Beneficial Fire",
                "Land Protection",
                "Not Defined",
                'Not Reported'
            ],
            owners: [
                    "Federal",
                    "Local",
                    "NGO",
                    "Private Industry",
                    "Private Non-Industry",
                    "State",
                    "Tribal",
                    'Not Reported'
            ],
            agencies: [
                "CA Environmental Protection Agency",
                "CA State Transportation Agency",
                "CA Natural Resources Agency",
                "Department of Defense",
                "Department of the Interior",
                "Department of Agriculture",
                "CA Air Resources Board",
                "Timber Companies",
                "Other",
                'Not Reported'
            ] 
        }

        this.setCheckedQuerysets = this.setCheckedQuerysets.bind(this);
        this.setQueryLayers = this.setQueryLayers.bind(this)
        this.setQueryLayerInfo = this.setQueryLayerInfo.bind(this)
        this.getQueryData = this.getQueryData.bind(this)
        this.addLayerByUser = this.addLayerByUser.bind(this);
        this.setCategories = this.setCategories.bind(this)
        this.setAgencies = this.setAgencies.bind(this)
        this.setOwners = this.setOwners.bind(this)
        this.setReminderVisible = this.setReminderVisible.bind(this)


        // this is a backup base map layer for Google Terrain Map
        /*
        this.backgroundLayer = new TileLayer({
            source: new Stamen({
                crossOrigin: "anonymous",
                layer: 'terrain'
            })
        });
        */

        this.backgroundLayer = new TileLayer({
            source: new XYZ({
                crossOrigin: "anonymous",
                url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
            })
        });

        // this.rrkBoundariesLayer = new OlLayerTile({
        //     visible: true,
        //     type: 'WMS',
        //     source: new OlSourceTileWMS({
        //         visible: true,
        //         url: 'https://twsa.ucsd.edu/geoserver/rrk_nv/wms',
        //         crossOrigin: "anonymous",
        //         params: {
        //             'LAYERS': 'rrk_nv:rrk_boundaries',
        //             'TILED': true
        //         },
        //         serverType: 'geoserver',
        //     })
        // });

        this.layers = [];
        for (var i = 0; i < config['Regional Resource Kit']['Southern California'].length; i++) {
            let item = config['Regional Resource Kit']['Southern California'][i];
            this.layers.push(
                new OlLayerTile({
                    visible: false,
                    opacity: 0.7,
                    type: 'WMS',
                    source: new OlSourceTileWMS({
                        url: item.url,
                        crossOrigin: "anonymous",
                        params: {
                            'LAYERS': item.layer,
                            'STYLES': undefined,
                            'SLD_BODY': getSLDForLayer(
                                item.layer,
                                item.color_start,
                                item.color_end,
                                item.max,
                                item.min,
                                item.max,
                                item.min,),
                            'TILED': true
                        },
                        serverType: 'geoserver',
                    })
                })
            )
        }

        for (i = 0; i < config['Interagency Tracking System'].length; i++) {
            let item = config['Interagency Tracking System'][i];
            this.layers.push(
                new OlLayerTile({
                    visible: false, //i==0,
                    opacity: 0.7,
                    type: 'WMS',
                    zIndex: 100 - i,
                    // type: 'WMSTime',
                    // timeFormat: 'YYYY-MM-DD',
                    // roundToFullHours: false,
                    source: new OlSourceTileWMS({
                        url: item.url,
                        crossOrigin: "anonymous",
                        params: {
                            'LAYERS': item.layer,
                            'TILED': true,
                            'cql_filter': item.cql_filter
                        }
                    })
                })
            )
        }

        this.map = new OlMap({
            layers: [
                this.backgroundLayer,
                // this.rrkBoundariesLayer,
                ...this.layers,
                ...this.state.queryLayers
            ],
            view: new OlView({
                projection: 'EPSG:4326',
                center: [-119.4179, 36.7783], //[-118.4194, 33.9592],
                zoom: 6.2 // 7.8
            })
        });

        this.map.on('loadstart', function () {
            document.getElementById("map").classList.add('spinner');
        });

        this.map.on('loadend', function () {
            document.getElementById("map").classList.remove('spinner');
        });

        this.map.addControl(new ScaleLine({
            units: 'us',
        }));
    }

    async setCategories(categories) {
        this.setState({"categories": categories })
    }

    async setAgencies(agencies){
        this.setState({"agencies": agencies})
    }

    async setOwners(owners){
        this.setState({"owners": owners})
    } 

    async setReminderVisible(value) {
        this.setState({'reminder_visible' : value})
    }

    static contextType = UserContext

    componentDidMount() {
        this.map.setTarget(this.mapDivId);
        this.setState({
            valueRanges: getMinMax()
        });

        // load data from indexedDB
        let openRequest = indexedDB.open("WFR_DB", 1);
        openRequest.onsuccess = function() {
            let db = openRequest.result;
            let transaction = db.transaction("data", "readwrite");
            let objectStore = transaction.objectStore("data");
            let request = objectStore.getAll();
            request.onerror = function(event) {
                console.log("Unable to retrieve data from IndexedDB!");
            };

            request.onsuccess = function(event) {
                if(request.result) {
                    for (let i=0; i<request.result.length; i++) {
                        let fileContent = request.result[i];
                        fileContent.definition = JSON.parse(fileContent.definition);
                        // console.log('filename: ', fileContent.filename);
                        // console.log('definition: ', JSON.stringify(fileContent.definition));
                    }
                } else {
                    console.log("couldn't be found in your database!");
                }
            };

        }

        this.setReminderVisible(true)
    }

    componentDidUpdate(prevProps) {

        console.log('componentDidUpdate got hit')

        if (this.props.display === 'map') {
            document.getElementById("map_container").style.display = 'Block';

            for (var i = 0; i < this.props.selectedDatasets.length; i++) {
                let item = this.props.selectedDatasets[i].mapping[0];
                let found = false;
                for (var j = 0; j < this.layers.length; j++) {
                    if (item.url === this.layers[j].getSource().getUrls().toString() &&
                        item.layer === this.layers[j].getSource().getParams().LAYERS.toString()) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    const layer = new OlLayerTile({
                        visible: this.props.selectedDatasets[i].folder.startsWith('/Regional Resource Kit') ? true : false,
                        opacity: 0.7,
                        type: 'WMS',
                        source: new OlSourceTileWMS({
                            url: item.url,
                            crossOrigin: "anonymous",
                            params: {
                                'LAYERS': item.layer,
                                'STYLES': undefined,
                                'TILED': true,
                                'cql_filter': item.cql_filter ? item.cql_filter : undefined
                            },
                            serverType: 'geoserver',
                        })
                    });

                    this.map.addLayer(layer);
                    this.layers.push(layer);
                }
            }

            // this.map.getLayers().forEach(layer => {
            this.layers.forEach(layer => {
                let found = false;

                for (i = 0; i < this.props.selectedDatasets.length; i++) {
                    let item = this.props.selectedDatasets[i].mapping[0];
                    if (layer && layer.getSource && layer.getSource().getUrls &&
                        item.url === layer.getSource().getUrls().toString() &&
                        item.layer === layer.getSource().getParams().LAYERS.toString()) {
                            found = true;
                            break;
                        }
                    }

                if (!found)
                {
                    for (i = 0; i < this.state.queryLayers.length; i++) {
                        let itemLayerId = this.state.queryLayers[i].getProperties()['layerId']

                        if (layer && layer.getProperties && itemLayerId === layer.getProperties()['layerId']) {
                                found = true;
                                break;
                            }
                        }
                }

                if (!found && layer && ((layer.getSource && layer.getSource() && layer.getSource().getParams)
                    || (layer.getProperties && layer.getProperties()['layerId'] 
                        && (layer.getProperties()['layerId'].includes('Intersection') == false))))
                {
                    this.map.removeLayer(layer);
                }
            });

            let tmp = [];
            for (j = 0; j < this.layers.length; j++) {
                let layer = this.layers[j];
                let found = false;
                for (i = 0; i < this.props.selectedDatasets.length; i++) {
                    let item = this.props.selectedDatasets[i].mapping[0];
                    if (item.url === layer.getSource().getUrls().toString() &&
                        item.layer === layer.getSource().getParams().LAYERS.toString()) {
                        found = true;
                        break;
                    }
                }

                if (!found) {
                    // pass
                } else {
                    tmp.push(layer);
                }
            }

            console.log("tmp here")
            console.log(tmp)

            tmp.forEach(layer => {
                console.log(layer.getSource().getParams().LAYERS.toString())
                console.log(layer.getVisible())
            })
            this.layers = tmp;

            // make sure layers are appropriately visible
            console.log(this.props.checkedDatasets)

            this.layers.forEach(e => {
                let key = e.getSource().getUrls().toString() + '/' + e.getSource().getParams().LAYERS.toString()
                if(key in this.props.checkedDatasets){
                    e.setVisible(this.props.checkedDatasets[key])
                }
            })

        } else {
            document.getElementById("map_container").style.display = 'None';
        }
    }

    setCheckedQuerysets(checkedQuerysets){
        this.setState({checkedQuerysets})
    }

    setQueryLayers(queryLayers){
        this.setState({"queryLayers": queryLayers})
    }

    setQueryLayerInfo(queryLayerInfo){
        this.setState(queryLayerInfo)
    }

    getQueryData(){
        let datasetInfo = {}
        // this.map.getLayers().forEach(layer => {
        this.map.getLayers().forEach(layer => {
            console.log(layer)
            if(layer.getVisible() && layer && layer.getSource
            && layer.getSource().getParams && layer.getSource().getUrls()){
                let key = layer.getSource().getParams().LAYERS.toString()
                let url = layer.getSource().getUrls().toString() 
                let [min, max] = this.props.valueRanges[url + '/' + key]
                if (layer.getSource().getParams().LAYERS.startsWith('rrk')){
                    datasetInfo[key] = [min, max]
                } else {
                    if(layer.getSource().getParams().cql_filter === undefined){
                        datasetInfo[key] = [`activity_end >=${min * 1000} and activity_end <= ${max * 1000}`]
                        console.log(datasetInfo[key])
                    }else{
                        datasetInfo[key] = [layer.getSource().getParams().cql_filter]
                    }
                }
            }
        })

        return datasetInfo
    }

    addLayerByUser = (layer) => {
        let newLayersByUser = [...this.state.layersByUser];
        newLayersByUser.push(layer);
        this.setState({
            layersByUser: newLayersByUser
        })
    }

    render() {
        return (
            <div>
            <div id="map_container" style={{height: 'calc(100vh - 45px)', position: 'relative'}}>
                <div id={this.mapDivId} style={{height: 'calc(100vh - 45px)', marginRight: '350px'}}/>
                <ForestDataMapPopover map={this.map}
                                      layers={this.layers}
                                      valueRanges={this.props.valueRanges}
                                      selectedDatasets={this.props.selectedDatasets}
                />
                <ForestDataMapQueryTool map={this.map} layers={this.layers}
                                        queryLayers={this.state.queryLayers}
                                        setQueryLayers={this.setQueryLayers}
                                        setQueryLayerInfo={this.setQueryLayerInfo}
                                        getQueryData={this.getQueryData}   
                                        queryLayerInfo={this.state.queryLayerInfo}
                                        checkedQuerysets={this.state.checkedQuerysets}                                  
                                        setCheckedQuerysets={this.setCheckedQuerysets}    
                                        access_token={this.context.user.access_token}                               
                />    
                {/* <ForestDataMapIntersect map={this.map} layers={this.layers}
                                        queryLayers={this.state.queryLayers}
                                        setQueryLayers={this.setQueryLayers}
                                        setQueryLayerInfo={this.setQueryLayerInfo}
                                        getQueryData={this.getQueryData}   
                                        queryLayerInfo={this.state.queryLayerInfo}
                                        checkedQuerysets={this.state.checkedQuerysets}                                  
                                        setCheckedQuerysets={this.setCheckedQuerysets}  /> */}

                <ForestDataMapDownload map={this.map} layers={this.layers} 
                                       valueRanges={this.props.valueRanges}  
                                       getQueryData={this.getQueryData}    
                                       access_token={this.context.user.access_token}  
                                       />
                <ForestDataMapReset map={this.map} layers={this.layers}/>
                <ForestDataMapLayerTree map={this.map}
                                        layers={this.layers}
                                        layersByUser={this.state.layersByUser}
                                        setValueRanges={this.props.setValueRanges}
                                        setQueryLayers={this.setQueryLayers}
                                        setDisplay={this.props.setDisplay}
                                        setCategory={this.props.setCategory}
                                        // setSelectedKeys={this.props.setSelectedKeys}
                                        // setDefaultKeys={this.props.setDefaultKeys}
                                        queryLayers={this.state.queryLayers}
                                        valueRanges={this.props.valueRanges}
                                        checkedDatasets={this.props.checkedDatasets}
                                        checkedQuerysets={this.state.checkedQuerysets}
                                        setCheckedQuerysets={this.setCheckedQuerysets}
                                        setCheckedDatasets={this.props.setCheckedDatasets}
                                        queryLayerInfo={this.state.queryLayerInfo}
                                        setQueryLayerInfo={this.setQueryLayerInfo}
                                        agencies={this.state.agencies}
                                        owners={this.state.owners}
                                        categories={this.state.categories}
                                        setAgencies={this.setAgencies}
                                        setCategories={this.setCategories}
                                        setOwners={this.setOwners}
                />
                <ForestDataMapSaveWorkspace map={this.map} layer={this.layers}
                                        selectedDatasets={this.props.selectedDatasets}
                                        checkedDatasets={this.props.checkedDatasets}    
                                        access_token={this.context.user.access_token}  
                                        jwtToken={this.context.user.jwtToken} 
                                        valueRanges={this.props.valueRanges}                                                                  
                />
                <DragDropSupport map={this.map} addLayerByUser={this.addLayerByUser}/>
            </div>
            <Modal 
                title="Reminder"
                open={this.state.reminder_visible}
                onOk={() => this.setReminderVisible(false)}
                okText="I understand"
                cancelButtonProps={{
                   disabled: true ,
                  }}
                style={{
                    minWidth:'100px',
                    zIndex: -1
                }}
                >                            
                <div>
                    This is a prototype and data available here is only provided for the purpose of gathering user feedback. Please acknowledge that you understand and will not use this data. 
                </div>
            </Modal>
        </div>
        )
    }
}


